<template>
  <div class="businessGoodDetail">
    <div class="businessGoodDetail-t">
      <div class="order">
        <div class="order-t">
          <div class="order-tl">兑换时间：{{ orderData.insertTime }}</div>
          <div :class="['order-tr', { active: orderData.status == '已核销' }]">
            {{ orderData.status }}
          </div>
        </div>
        <div class="order-b">
          <div class="order-bl">
            <img :src="orderData.imageUrl" alt="" />
          </div>
          <div class="order-br">
            <div class="title">{{ orderData.title }}</div>
            <div class="price">{{ orderData.pointsPrice }}甜蜜值</div>
            <div class="num">x{{ orderData.pointsPrice }}</div>
          </div>
        </div>
      </div>
      <div class="total">总 {{ orderData.pointsChangeNum }}甜蜜值</div>
    </div>
    <div class="businessGoodDetail-b">
      <div class="item">
        <div class="item-l">下单用户：</div>
        <div class="item-r">{{ orderData.userName }}</div>
      </div>
      <div class="item">
        <div class="item-l">联系电话：</div>
        <div class="item-r">{{ orderData.mobileNum }}</div>
      </div>
      <div class="item">
        <div class="item-l">所属社区：</div>
        <div class="item-r">{{ orderData.spaceName }}</div>
      </div>
      <div class="item">
        <div class="item-l">所属小区：</div>
        <div class="item-r">{{ orderData.communityName }}</div>
      </div>
      <div class="item">
        <div class="item-l">订单号：</div>
        <div class="item-r">{{ orderData.orderSn }}</div>
      </div>
      <div class="item" v-if="orderData.status == '已核销'">
        <div class="item-l">核销时间：</div>
        <div class="item-r">{{ orderData.completedTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderIdUrl } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "businessGoodDetail",
  data() {
    return {
      id: "",
      orderData: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getGoodsDetail();
  },
  methods: {
    async getGoodsDetail() {
      let params = {
        id: this.id,
      };
      let res = await this.$axios.get(`${getOrderIdUrl}`, { params });
      if (res.code === 200) {
        this.orderData = res.data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.businessGoodDetail {
  min-height: 100%;
  background: #f9f9f9;
  padding: 26px 28px;
  box-sizing: border-box;
  .businessGoodDetail-t {
    border-bottom: 2px solid #eeeeee;
    margin-bottom: 44px;
  }
  .order {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    border-radius: 16px;

    .order-t {
      padding: 0 20px;
      line-height: 78px;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      display: flex;
      justify-content: space-between;
      .order-tr {
        color: rgba(254, 150, 22, 1);
      }
      .active {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .order-b {
      display: flex;
      padding: 22px;

      position: relative;
      .order-bl {
        width: 180px;
        height: 180px;
        margin-right: 30px;
        background: #f6f6f6;
        text-align: center;
        border-radius: 16px;
        padding: 10px;
        img {
          width: 100;
          height: 100%;
          border-radius: 16px;
          vertical-align: middle;
        }
        &:after {
          content: "";
          display: inline-block;
          width: 0;
          height: 100%;
          vertical-align: middle;
        }
      }
      .order-br {
        font-size: 32px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
        padding-top: 30px;
        .title {
          margin: 0px 0 12px 0;
          font-size: 32px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 44px;
          width: 260px;
        }
        .price {
          color: rgba(0, 0, 0, 0.85);
          position: absolute;
          top: 58px;
          right: 28px;
        }
        .num {
          color: rgba(0, 0, 0, 0.85);
          position: absolute;
          font-size: 24px;
          top: 108px;
          right: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 34px;
        }
      }
    }
  }
  .total {
    text-align: right;
    font-size: 28px;
    padding: 26px 0 48px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
  }
  .businessGoodDetail-b {
    .item {
      display: flex;
      font-size: 32px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 60px;
      .item-l {
        width: 164px;
        flex-shrink: 0;
      }
      .item-r {
        flex: 1;
        word-break: break-all;
      }
    }
  }
}
</style>
